.mb_modifier_details {
  border: 1px solid #cdcdcd;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
}
.mb_modifier_details__info {
  display: inline-block;
  max-width: 80%;
}
.mb_modifier_details__info p {
  margin: 0;
}
.mb_modifier_details__name {
  font-weight: bold;
  margin-right: 20px;
}
.mb_modifier_details__description {
  font-style: italic;
  opacity: .8;
}
.mb_modifier_details:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}