.mb_modifiers_groups_details {
  background-color: #F3F3F3;
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
}
.mb_modifiers_groups_details p {
  margin: 0;
  display: flex;
}
.mb_modifiers_groups_details__info {
  display: inline-block;
  max-width: 80%;
}
.mb_modifiers_groups_details__name {
  font-weight: bold;
}
.mb_modifiers_groups_details__settings {
  font-size: .8rem;
  opacity: .8;
}
.mb_modifiers_groups_details:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}
