.list {
  margin: 0;
  padding: 0;
  background-color: #e0e4e7;
}
.list li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style-type: none;
  border-right: 1px solid #8d9094;
}
.list li a {
  color: #000;
  background-color: #E9ECEF;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  padding: 10px 15px;
}
.list li a.active {
  background-color: #fff;
}
.list li a:hover {
  background-color: #fff;
}
.activeItem {
  background-color: #fff !important;
}