.mb_tools {
  margin-bottom: 50px;
  text-align: right;
}

.mb_tools button {
  margin-left: 10px;
}

.mb_right_toolbar {
  float: right;
}
.mb_onhover {
  display: inline-block;
  margin-right: 20px;
  opacity: 0;
  transition: all .2s linear;
  transform: translateY(10px)
}

/* icons */
.mb_icon {
  height: 20px;
  display: inline-block;
  margin: 0 10px;
  opacity: 0.6;
}
.mb_icon--visibility {
  height: 30px;
}

/* forms */
.mb_container input {
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #cdcdcd;
}
.mb_container label {
  margin-top: 10px;
  width: 100%;
  font-weight: bold;
  text-align: left;
}

.mb_container select {
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #cdcdcd;
}
