.list {
  padding: 0;
  margin: 0;
}
.item {
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* border: 1px solid #cdcdcd; */
  display: flex;
  align-items: flex-start;
}
.itemValue {
  flex: 1;
}
.dragIcon {
  width: 40px;
  margin-top: 30px;
  text-align: center;
  cursor: row-resize;
  opacity: 0.4;
}