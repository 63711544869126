.mb_item {
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
}
.mb_item__details {
  display: inline-block;
  max-width: 80%;
}
.mb_item__name {
  font-size: 21px;
  margin: 0;
}
.mb_item__description {
  font-style: italic;
  color: #736D6D;
  font-size: 16px;
  margin: 0;
}
.mb_item:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}