.mb_menu {
  margin-top: 40px;
  padding: 8px 20px 8px 0;
  position: relative;
}
.mb_menu.unactive {
}
.mb_menu__name {
  display: inline-block;
  font-size: 21px;
  font-weight: bold;
  margin: 0;
}
.mb_categories {
  margin-top: 20px;
}
.mb_menu:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}