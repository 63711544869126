.img {
  max-height: 100px;
  max-width: 100%;
  margin-bottom: 8px;
}
.dropzone {
  height: 160px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 6px;
  text-align: center;
  padding-top: 20px;
}