.modal-dialog {
  overflow-y: initial !important;
  z-index: 2;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-backdrop {
  display: none;    
}

.modal::after {
  content: '';
  background-color: rgba(0,0,0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}