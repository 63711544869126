.mb_variation {
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 10px;
  position: relative;
  margin-left: 120px;
}
.mb_variation__name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}
.mb_variation__price {
  margin-left: 20px;
  font-weight: normal;
}
.mb_variation_groups {
  margin-top: 10px;
}
.mb_variation:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}

.mb_variation_modal_groups label {
 display: inline-block;
 margin: 2px 8px;
 font-weight: normal;
 width: auto;
 border: 1px solid #DBDBDB;
 background-color: #f3f3f3;
 border-radius: 8px;
 padding: 2px 6px;
}
.mb_variation_modal_groups label input {
 display: inline-block;
}