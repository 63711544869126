.mb_category {
  background-color: #FFF6EC;
  border-radius: 8px;
  padding: 16px 20px;
  margin: 8px 0;
  position: relative;
}
.mb_category__details {
  display: inline-block;
  max-width: 80%;
}
.mb_category__name {
  margin: 0;
  font-size: 21px;
  font-weight: bold;
}
.mb_category__description {
  font-style: italic;
  color: #736D6D;
  font-size: 16px;
  margin: 0;
}
.mb_category:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}