.mb_subcategory {
  background-color: #F3F3F3;
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 10px;
  position: relative;
}
.mb_subcategory__name {
  font-size: 21px;
  margin: 0;
  display: inline-block;
}
.mb_subcategory__name.hidden {
  opacity: .2;
}
.mb_subcategory:hover > .mb_right_toolbar > .mb_onhover {
  opacity: 1;
  transform: translate(0)
}