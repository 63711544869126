.mb_modifiers_group__item {
  display: inline-block;
  padding: 0px 6px;
  background-color: #E0DFDF;
  border-radius: 8px;
  color: #8B8B8B;
  margin: 2px 6px;
  cursor: move;
}
.mb_modifiers_group__item:first-child {
  margin-left: 0;
}